
  import { Component, Vue } from 'vue-property-decorator';
  import { CREATE_COMPETITION, RESET_STATE } from '@/store/competitions';
  import CompetitionEdit from '@/components/CompetitionEdit.vue';
  import { FETCH_SPORTS } from '@/store/sports';
  import { FETCH_LEAGUES } from '@/store/leagues';

  @Component({
    components: { CompetitionEdit }
  })
  export default class CompetitionsCreate extends Vue {
    created() {
      this.$store.commit(RESET_STATE);

      this.$store.dispatch(FETCH_SPORTS, {
        is_supported: 1,
        with: ['supported_bet_types', 'supported_leagues'],
        all: 1
      });
      this.$store.dispatch(FETCH_LEAGUES, {
        is_supported: 1,
        all: 1
      });
    }

    public save() {
      if (!(this.$refs.competitionEdit as CompetitionEdit).validate()) {
        return;
      }

      this.$store.dispatch(CREATE_COMPETITION)
        .then(() => this.$router.push({ name: 'app.competitions.list' }));
    }
  }
